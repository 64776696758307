import React from "react";
import "../styles/testimonial_styles.scss";
import { TestimonialSphere } from "./";
export const Testimonial = () => {
  return (
    <div className="mb_testimonial">
      <div className="mb_testimonial_container">
        <h2 className="mb_testimonial_container-header">
          Feedbacks from Our Students
        </h2>
        {/* <TestimonialSphere /> */}
      </div>
    </div>
  );
};
