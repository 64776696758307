import { DownloadApp, Hero, ServicesOffered, Testimonial } from "../components";

export const HomePage = () => {
  return (
    <div>
      <Hero />
      <ServicesOffered />
      <DownloadApp />
      <Testimonial />
    </div>
  );
};
