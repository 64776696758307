export const RE_DIGIT = new RegExp(/^\d+$/);

export const AllRouteConstants = {
  landingPage: {
    index: "/",
    home: "/",
    contactUs: "/contact-us",
    aboutUs: "/about-us",
  },
};
